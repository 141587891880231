export const resultsUrl = (locale: string) => (locale === 'de-de' ? 'treffer' : 'results');

export const generateUrl = (query: string) => {
  const re = query
    .replace(/&/g, '__g1__')
    .replace(/[ /]/g, '__g2__')
    .replace('--', '__g3__')
    .replace('-', '__g4__');

  return encodeURIComponent(
    re
      .replace(/__g1__/g, 'und')
      .replace(/__g2__/g, '-')
      .replace(/__g3__/g, '---')
      .replace(/__g4__/g, '--'),
  );
};

export const generateQueryFromUrl = (url = '') => {
  const urlGroup = decodeURIComponent(url)
    .replace(/und/g, '__g1__')
    .replace(/---/g, '__g4__')
    .replace(/--/g, '__g3__')
    .replace(/-/g, '__g2__');

  return urlGroup
    .replace(/__g1__/g, '&')
    .replace(/__g2__/g, ' ')
    .replace(/__g3__/g, '-')
    .replace(/__g4__/g, '- ');
};
