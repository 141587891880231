import PeLink from '@pe-libs/react-components/build/components/PeLink';
import React, { FC } from 'react';
import classNames from '#utils/classNames';
import useStyles from './styles';

type Props = {
  href: string;
  currentColor?: boolean;
  title?: string;
  rel?: string;
};

const Link: FC<Props> = ({ href, children, title, currentColor, rel }) => {
  const styles = useStyles();
  const linkClassName = classNames(styles.link, {
    [styles.currentColor]: currentColor === true,
  });

  const isExternal =
    href.includes('http') || href.startsWith('www') || href.includes('tel:') || href.includes('mailto:');

  return (
    <PeLink
      className={linkClassName}
      href={href}
      target={isExternal ? '_blank' : ''}
      title={title ?? children?.toString()}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => event.stopPropagation()}
      rel={rel}
    >
      {children}
    </PeLink>
  );
};

export default Link;
