import Facebook from '@pe-libs/react-components/build/components/PeIcons/Facebook.svg';
import Instagram from '@pe-libs/react-components/build/components/PeIcons/Instagram.svg';
import LinkedIn from '@pe-libs/react-components/build/components/PeIcons/LinkedIn.svg';
import Twitter from '@pe-libs/react-components/build/components/PeIcons/Twitter.svg';
import Xing from '@pe-libs/react-components/build/components/PeIcons/Xing.svg';
import Youtube from '@pe-libs/react-components/build/components/PeIcons/Youtube.svg';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React from 'react';
import useRedirect from '#hooks/useRedirect';
import messages from './messages';

type MessageType = ReturnType<typeof useIntlMessage>;
type UrlType = ReturnType<typeof useRedirect>['urlToLivePage'];

const data = (formatMessage: MessageType, urlToLivePage: UrlType) => ({
  product: [
    {
      label: formatMessage(messages.ratingSeals),
      href: urlToLivePage('ratingSeals'),
    },
    {
      label: formatMessage(messages.customerSurveys),
      href: urlToLivePage('customerSurveys'),
    },
    {
      label: formatMessage(messages.advantages),
      href: urlToLivePage('advantages'),
    },
    {
      label: formatMessage(messages.enterpriseSolution),
      href: urlToLivePage('enterpriseSolution'),
    },
    {
      label: formatMessage(messages.affiliateProgram),
      href: urlToLivePage('affiliateProgram'),
    },
    {
      label: formatMessage(messages.awards),
      href: urlToLivePage('awards'),
    },
  ],
  aboutUs: [
    {
      label: formatMessage(messages.whyProvenexpert),
      href: urlToLivePage('whyProvenexpert'),
    },
    {
      label: formatMessage(messages.ourCompany),
      href: urlToLivePage('ourCompany'),
    },
    {
      label: formatMessage(messages.team),
      href: urlToLivePage('team'),
    },
    {
      label: formatMessage(messages.careers),
      href: urlToLivePage('careers'),
    },
    {
      label: formatMessage(messages.testimonials),
      href: urlToLivePage('testimonials'),
    },
    {
      label: formatMessage(messages.contact),
      href: urlToLivePage('contact'),
    },
  ],
  knowledge: [
    {
      label: formatMessage(messages.knowledge),
      href: urlToLivePage('blog'),
    },
    {
      label: formatMessage(messages.faq),
      href: urlToLivePage('faq'),
    },
    {
      label: formatMessage(messages.customerReviewPlatforms),
      href: urlToLivePage('customerReviewPlatforms'),
    },
    {
      label: formatMessage(messages.customerReviews),
      href: urlToLivePage('customerReviews'),
    },
    {
      label: formatMessage(messages.customerSatisfaction),
      href: urlToLivePage('customerSatisfaction'),
    },
    {
      label: formatMessage(messages.reviewGuidelines),
      href: urlToLivePage('reviewGuidelines'),
    },
    {
      label: formatMessage(messages.events),
      href: urlToLivePage('events'),
    },
  ],
  service: [
    {
      label: formatMessage(messages.press),
      href: urlToLivePage('press'),
    },
    {
      label: formatMessage(messages.newsletter),
      href: urlToLivePage('newsletter'),
    },
    {
      label: formatMessage(messages.onlineDemo),
      href: urlToLivePage('onlineDemo'),
    },
    {
      label: formatMessage(messages.expertRating),
      href: urlToLivePage('expertRating'),
    },
    {
      label: formatMessage(messages.expertDirectory),
      href: urlToLivePage('expertDirectory'),
    },
  ],
});

export const dataColumnLinks = (formatMessage: MessageType, urlToLivePage: UrlType) => {
  const dataColumn = data(formatMessage, urlToLivePage);

  return [
    {
      title: formatMessage(messages.col1Title),
      data: dataColumn.product,
    },
    {
      title: formatMessage(messages.col2Title),
      data: dataColumn.aboutUs,
    },
    {
      title: formatMessage(messages.col3Title),
      data: dataColumn.knowledge,
    },
    {
      title: formatMessage(messages.col4Title),
      data: dataColumn.service,
    },
  ];
};

export const dataSocials = [
  {
    label: 'Twitter',
    icon: <Twitter />,
    href: 'https://twitter.com/ProvenExpert',
  },
  {
    label: 'Facebook',
    icon: <Facebook />,
    href: 'https://www.facebook.com/ProvenExpert',
  },
  {
    label: 'Xing',
    icon: <Xing />,
    href: 'https://www.xing.com/companies/provenexpert.com',
  },
  {
    label: 'LinkedIn',
    icon: <LinkedIn />,
    href: 'https://www.linkedin.com/company/provenexpert',
  },
  {
    label: 'Youtube',
    icon: <Youtube />,
    href: 'http://www.youtube.com/channel/UC3BXKiQiE8qmSkres6PJJ9w',
  },
  {
    label: 'Instagram',
    icon: <Instagram />,
    href: 'https://www.instagram.com/provenexpert_com/',
  },
];

export const dataBottomLinks = (formatMessage: MessageType, urlToLivePage: UrlType) => [
  {
    label: formatMessage(messages.termsAndConditions),
    href: urlToLivePage('termsAndConditionsOfUse'),
  },
  {
    label: formatMessage(messages.privacyPolicy),
    href: urlToLivePage('privacyPolicy'),
  },
  {
    label: formatMessage(messages.qualityAssurance),
    href: urlToLivePage('qualityAssurance'),
  },
  {
    label: formatMessage(messages.legalNotice),
    href: urlToLivePage('legalNotice'),
  },
];
