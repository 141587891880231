import imgLogoDarkMode from '@pe-libs/react-components/build/assets/common/logos/logo-darkmode.svg';
import PeImage from '@pe-libs/react-components/build/components/PeImage';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSearchUrl } from '#helpers/searchUrl';
import { getUserLocale } from '#modules/app/selectors';
import messages from './messages';
import useStyles from './styles';

const Logo: FC = () => {
  const formatMessage = useIntlMessage();
  const styles = useStyles();
  const locale = useSelector(getUserLocale)?.toLowerCase() ?? 'de-de';

  return (
    <PeLink href={`/${locale}/${getSearchUrl(locale)}/`} title={formatMessage(messages.logoTitle)}>
      <div className={styles.logo}>
        <PeImage
          width={173}
          height={52}
          layout="fixed"
          alt={formatMessage(messages.logoTitle)}
          src={imgLogoDarkMode as string}
        />
      </div>
    </PeLink>
  );
};

export default Logo;
