import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import React, { FC } from 'react';
import Link from '#modules/expertDirectory/components/Link';
import useStyles from './styles';
import { ColumnLinkType } from './types';

type Props = {
  title: string;
  data: ColumnLinkType[];
};

const ColumnLinks: FC<Props> = ({ title, data }) => {
  const styles = useStyles();

  return (
    <div className={styles.columnContainer}>
      <PeTypography variant="h6" className={styles.title}>
        {title}
      </PeTypography>
      {data.map((link) => (
        <div key={link.label} className={styles.linkContainer}>
          <Link href={link.href} currentColor={true}>
            {link.label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ColumnLinks;
