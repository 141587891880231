import PeImage from '@pe-libs/react-components/build/components/PeImage';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getUserLocale } from '#modules/app/selectors';
import useStyles from './styles';

const WidgetReviews: FC = () => {
  const locale = useSelector(getUserLocale) ?? 'en-us';
  const countryCode = locale.split('-')[0];
  const styles = useStyles();

  return (
    <div>
      {countryCode === 'de' ? (
        <PeLink
          className={styles.widgetReviews}
          href="https://www.provenexpert.com/de-de/provenexpert-com/"
          title="Kundenbewertungen &amp, Erfahrungen zu ProvenExpert.com. Mehr Infos anzeigen."
          target="_blank"
        >
          <PeImage
            src="https://images.provenexpert.com/bf/85/cd3394fc0a9d8d4463cb5da3788e/widget_landscape_180_0.png"
            alt="Erfahrungen &amp, Bewertungen zu ProvenExpert.com"
            width={180}
            height={150}
            layout="fixed"
          />
        </PeLink>
      ) : (
        <PeLink
          className={styles.widgetReviews}
          href="https://www.provenexpert.com/en-us/provenexpert-com/"
          title="Customer reviews &amp, experiences for ProvenExpert.com. Show more information."
          target="_blank"
        >
          <PeImage
            src="https://images.provenexpert.com/bf/85/cd3394fc0a9d8d4463cb5da3788e/widget_landscape_180_us_0.png"
            alt="Ratings &amp; reviews for ProvenExpert.com"
            width={180}
            height={150}
            layout="fixed"
          />
        </PeLink>
      )}
    </div>
  );
};

export default WidgetReviews;
