import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserLocale } from '#modules/app/selectors';
import useStyles from './styles';

const WidgetRichSnippet: FC = () => {
  const locale = useSelector(getUserLocale) ?? 'en-us';
  const countryCode = locale.split('-')[0];
  const styles = useStyles();

  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'peRichSnippet';
    /* eslint max-len: ["error", { "ignoreUrls": true }] */
    script.src = `https://www.provenexpert.com/widget/richsnippet.js?u=1ZwAjOao24TpmqGB1xQBmImAjNQZjNwA&v=2&l=${countryCode}`;
    script.async = true;
    document.body.appendChild(script);

    return function cleanup() {
      const richSnippetSelector = document.querySelector('#peRichSnippet');

      if (richSnippetSelector) {
        document.body.removeChild(richSnippetSelector);
      }
    };
  }, [countryCode]);

  return (
    <div className={styles.widgetRichSnippet}>
      <div className="pe-richsnippets" />
    </div>
  );
};

export default WidgetRichSnippet;
