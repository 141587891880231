import { ParsedUrlQuery } from 'querystring';
import { getKeyword, getLocation } from '#helpers/query';
import { resultsUrl } from '#modules/expertDirectory/helpers';

export const getSearchUrl = (locale: string) => (locale === 'de-de' ? 'suche' : 'search');

export const getSearchFinalUrl = ({
  locale,
  query,
  asPath,
}: {
  locale: string;
  query: ParsedUrlQuery;
  asPath: string;
}) => {
  const searchUrl = getSearchUrl(locale);
  const keyword = getKeyword(query);
  const location = getLocation(query);

  if (!asPath.includes(getSearchUrl(locale)) && query.slugs) {
    return `/${locale.toLowerCase()}/${searchUrl}/${query.slugs?.[0] ? `${query.slugs[0]}` : ''}${
      typeof query.location === 'string' ? `/${query.location}` : ''
    }`
      .replace('%26', 'und-')
      .replace('%20', '-');
  }

  //  e.g /de-de/expertDirectory/results?keyword=Rechtsdienstleistungen =>
  // /de-de/suche/treffer?keyword=Rechtsdienstleistungen
  if (!asPath.includes(getSearchUrl(locale)) && query.keyword) {
    return `/${locale.toLowerCase()}/${getSearchUrl(locale)}/${resultsUrl(locale)}?keyword=${keyword}${
      location ? `&location=${location}` : ''
    }`
      .replace('%26', 'und-')
      .replace('%20', '-');
  }

  if (asPath.includes('de-de') && asPath.includes('results')) {
    return `${asPath.replace('results', 'treffer')}`.replace('%26', 'und-').replace('%20', '-');
  }

  return asPath.replace('%26', 'und-').replace('%20', '-');
};

export const getUrlWithoutParameters = (asPath: string, host?: string) => {
  if (!host) {
    return asPath.split('?')[0];
  }

  const url = `https://${host}${asPath.split('?')[0]}`;

  if (url[url.length - 1] !== '/') {
    return `${url}/`;
  }

  return url;
};
