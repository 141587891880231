import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ratingSeals: { id: 'system-slug-ratingSeals' },
  customerSurveys: { id: 'system-slug-customerSurveys' },
  advantages: { id: 'system-slug-advantages' },
  enterpriseSolution: { id: 'system-slug-enterpriseSolution' },
  affiliateProgram: { id: 'system-slug-affiliateProgram' },
  awards: { id: 'system-slug-awards' },
  whyProvenexpert: { id: 'system-slug-whyProvenexpert' },
  ourCompany: { id: 'system-slug-ourCompany' },
  team: { id: 'system-slug-team' },
  careers: { id: 'system-slug-careers' },
  testimonials: { id: 'system-slug-testimonials' },
  contact: { id: 'system-slug-contact' },
  faq: { id: 'system-slug-faq' },
  customerReviewPlatforms: { id: 'system-slug-customerReviewPlatforms' },
  customerReviews: { id: 'system-slug-customerReviews' },
  customerSatisfaction: { id: 'system-slug-customerSatisfaction' },
  reviewGuidelines: { id: 'system-slug-reviewGuidelines' },
  press: { id: 'system-slug-press' },
  newsletter: { id: 'system-slug-newsletter' },
  onlineDemo: { id: 'system-slug-onlineDemo' },
  expertRating: { id: 'system-slug-expertRating' },
  expertDirectory: { id: 'system-slug-expertDirectory' },
  termsAndConditionsOfUse: { id: 'system-slug-termsAndConditionsOfUse' },
  privacyPolicy: { id: 'system-slug-privacyPolicy' },
  qualityAssurance: { id: 'system-slug-qualityAssurance' },
  legalNotice: { id: 'system-slug-legalNotice' },
});

export default messages;
