import { createSelector } from '@reduxjs/toolkit';
import { StateType } from '../../../types';

export const getExpertCompassGlobalLoading = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => expertDirectory.global.loading,
);

export const getNumberOfCustomerReviews = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => expertDirectory.global.customerReviewsInMillions,
);

export const getExternalRatingCrawlerCount = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => (expertDirectory.global.data?.[0]?.attributes as { count: number })?.count,
);
