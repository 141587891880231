/* eslint-disable import/no-unused-modules */
import { getPublicEnvironmentValue } from '@pe-libs/react-components/build/lib/environment';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useSelector } from 'react-redux';
import { getUserLocale } from '#modules/app/selectors';
import slugMessages from './messages';

export type LiveUrlListType =
  | keyof typeof slugMessages
  | 'home'
  | 'login'
  | 'register'
  | 'blog'
  | 'cookies'
  | 'events'
  | 'affiliateProgram';
export type UrlToLivePageType = (nameOfUrl: LiveUrlListType) => string;
export const liveUrlList = Object.keys(slugMessages);

const createUrl = (
  nameOfUrl: LiveUrlListType,
  formatMessage: ReturnType<typeof useIntlMessage>,
  locale: string,
  provenemployer?: boolean,
) => {
  const base = getPublicEnvironmentValue('WWW_HOST', Boolean(provenemployer)).replace(/\/$/, '');
  const countryCode = locale.split('-')[0];

  switch (nameOfUrl) {
    case 'home': {
      return `${base}/${locale}/`;
    }

    case 'login': {
      return `${base}/${locale}/login`;
    }

    case 'register': {
      return `${base}/register`;
    }

    case 'blog': {
      return `https://blog.provenexpert.com/${countryCode}`;
    }

    case 'events': {
      return `https://lp.provenexpert.com/${countryCode}/provenexpert-event-overview`;
    }

    case 'affiliateProgram': {
      return `https://lp.provenexpert.com/${countryCode}/partnerprogramm/`;
    }

    case 'cookies': {
      return `${base}/${locale}/${
        locale.includes('de') ? 'datenschutzbestimmungen' : 'privacy-policy'
      }/#section2chapter7`;
    }

    case 'faq': {
      return `https://help.provenexpert.com/${countryCode}/`;
    }

    default: {
      return `${base}/${locale.toLowerCase()}/${formatMessage(slugMessages[nameOfUrl])}/`;
    }
  }
};

const useRedirect = () => {
  const locale = useSelector(getUserLocale)?.toLowerCase() ?? 'en-us';
  const formatMessage = useIntlMessage();

  const redirectToLivePage = (nameOfUrl: LiveUrlListType, provenemployer?: boolean): void =>
    window.location.assign(createUrl(nameOfUrl, formatMessage, locale, provenemployer));

  const urlToLivePage = (nameOfUrl: LiveUrlListType, provenemployer?: boolean): string =>
    createUrl(nameOfUrl, formatMessage, locale, provenemployer);

  return { redirectToLivePage, urlToLivePage };
};

export default useRedirect;
