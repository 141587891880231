import { ParsedUrlQuery } from 'querystring';
import { isArray } from '@pe-libs/react-components/build/lib/helper';

export const getKeyword = (query: ParsedUrlQuery) => {
  if (query.slugs) {
    return isArray(query.slugs) ? query.slugs[0] : query.slugs;
  }

  if (query.keyword) {
    return isArray(query.keyword) ? query.keyword[0] : query.keyword;
  }

  return '';
};

export const getLocation = (query: ParsedUrlQuery) => {
  if (query.slugs) {
    return isArray(query.slugs) ? query.slugs[1] : query.slugs;
  }

  if (query.location) {
    return isArray(query.location) ? query.location[0] : query.location ?? '';
  }

  return '';
};

export const getQueryParameters = (query: ParsedUrlQuery, parameter: 'page' | 'lang' | 'id' | 'userIdPartner') => {
  if (query?.[parameter]) {
    return isArray(query[parameter]) ? query[parameter][0] : query[parameter];
  }

  switch (parameter) {
    case 'page':
      return '1';

    case 'lang':
      return 'de-de';

    default:
      return '';
  }
};
