import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import Link from '#modules/expertDirectory/components/Link';
import messages from './messages';
import useStyles from './styles';

type Props = {
  data: Array<{
    label: string;
    href: string;
  }>;
};

const BottomLinks: FC<Props> = ({ data }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();

  return (
    <div>
      <ul className={styles.bottomLinks}>
        {data.map((link) => (
          <li key={link.label}>
            <Link href={link.href} currentColor={true}>
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.copyrightText}>
        {formatMessage(messages.copyright, { currentYear: new Date().getFullYear() })}
      </div>
    </div>
  );
};

export default BottomLinks;
