import PeLink from '@pe-libs/react-components/build/components/PeLink';
import React, { FC, ReactElement } from 'react';
import useStyles from './styles';

type Props = {
  data: Array<{
    label: string;
    icon: ReactElement;
    href: string;
  }>;
};

const Socials: FC<Props> = ({ data }) => {
  const styles = useStyles();

  return (
    <div className={styles.socialContainer}>
      {data.map((social) => (
        <div key={social.label} className={styles.socialIcon}>
          <PeLink title={social.label} href={social.href} target="_blank">
            {social.icon}
          </PeLink>
        </div>
      ))}
    </div>
  );
};

export default Socials;
