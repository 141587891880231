import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles('expert-directory-link', ({ palette: { primary } }) => ({
  link: {
    position: 'relative',
    color: primary.main,
    fontSize: 'inherit',
  },
  currentColor: {
    color: 'currentColor',
  },
}));

export default useStyles;
