import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles(
  'expert-directory-footer',
  ({
    palette: { grey, common, secondary },
    breakpoints: {
      up,
      values: { lg },
    },
    typography,
  }) => ({
    footer: {
      padding: '2.625rem 0',
      background: grey[800],
      color: common.white,

      [up('md')]: {
        padding: '3.5rem 0 2rem',
      },
    },
    container: {
      maxWidth: `${lg}px`,
      margin: '0 auto',
      padding: '0 1rem',

      [up('md')]: {
        padding: '0 1.5rem',
      },
    },
    title: {
      color: secondary.main,
      marginBottom: '1.25rem',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: typography.fontWeightSemiBold,
      letterSpacing: '0.25px',
      textTransform: 'uppercase',
    },
    linksContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',

      [up('md')]: {
        paddingBottom: '3.9375rem',
      },
    },
    columnContainer: {
      width: '50%',
      paddingBottom: '2.5rem',

      [up('md')]: {
        width: 'auto',
        paddingBottom: 0,
      },
    },
    linkContainer: {
      fontSize: '0.875rem',
      lineHeight: '1.3125rem',
      letterSpacing: '0.15px',
      marginBottom: '0.9rem',
      color: common.white,
    },
    logo: {
      marginBottom: '1.4375rem',
    },
    bottomContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

      [up('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    socialContainer: {
      display: 'flex',
      fontSize: '2rem',
      marginBottom: '2rem',
      justifyContent: 'center',

      [up('md')]: {
        marginBottom: 0,
        justifyContent: 'flex-start',
      },
    },
    socialIcon: {
      height: '2rem',
      width: '2rem',
      marginRight: '1rem',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    copyrightText: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '0.75rem',

      [up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    bottomLinks: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      listStyleType: 'none',
      fontSize: '0.75rem',
      color: common.white,
      paddingBottom: '0.5rem',

      [up('md')]: {
        paddingBottom: '0.25rem',
      },

      '& li': {
        width: '50%',

        '&:before': {
          content: '"|"',
          padding: '0 0.5rem',
        },

        '&:nth-child(odd)': {
          display: 'flex',
          justifyContent: 'flex-end',

          '&:before': {
            display: 'none',

            [up('sm')]: {
              display: 'flex',
            },
          },
        },

        '&:nth-child(odd):first-child:before': {
          display: 'none',
        },

        [up('sm')]: {
          width: 'auto',
        },
      },
    },
    bottomLeftContainer: {
      [up('md')]: {
        marginTop: '-1.5rem',
      },
    },
    widgetReviews: {
      display: 'inline-block',
      borderBottom: 0,
      width: '180px',
      padding: 0,
      textDecoration: 'none',
    },
    widgetRichSnippet: {
      marginBottom: '2rem',
      fontSize: '0.875rem',

      [up('md')]: {
        marginBottom: '0.5rem',
      },

      '& [property="aggregateRating"]': {
        display: 'flex',
        alignItems: 'center',
      },

      '& #pe_stars': {
        width: '5.7rem',
        top: '-3px',
      },

      '& #pe_name': {
        display: 'none',
      },
    },
  }),
);

export default useStyles;
